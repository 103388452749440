<template>
    <client-page class="app--main">
        <section class="new-main">
            <v-container>
                <h3 class="tit mb-90 mb-md-80 mb-lg-120 word-keep-all">
                    We sincerely thank everyone <br />
                    <span class="primary--text">who visited Link-Net Korea.</span>
                </h3>
                <div class="about-banner">
                    <div class="about-banner__logo">
                        <v-img src="/images/sub/about/about/logo.jpg" class="w-100" data-aos="fade-left" data-aos-delay="200" />
                    </div>
                    <v-img src="/images/sub/about/about/banner.jpg" class="about-banner__inner" />
                </div>
                <div class="mt-40 mt-lg-60">
                    <p class="tit-txt line-height-15 mb-6 mb-lg-12">
                        Link-Net Korea is a young company created by top engineers and consultants to provide a safer and more prosperous Internet network environment.
                    </p>
                    <p class="tit-txt line-height-15 text-lg-pre-wrap">
                        We carry out a number of projects for major information and communication infrastructure such as government agencies, public offices, and national defense, as well as financial institutions and private companies such as banks, securities firms, and capital.
                    </p>
                </div>
            </v-container>
        </section>

        <popup-layer></popup-layer>
    </client-page>
</template>

<script>
import api from "@/api";
import ClientPage from "@/pages/client/en/templates/ClientPage.vue";
import MainBanners from "@/components/client/main/main-banners.vue";
import MainSection from "@/components/client/main/main-section.vue";
import MainVisual from "@/components/client/main/main-visual.vue";
import MainTextBanner from "@/components/client/main/main-text-banner.vue";
import MainAuctionsBanner from "@/components/client/main/main-auctions-banner.vue";
// import BoardList from "@/components/client/board/board-list.vue";
import PopupLayer from "@/components/client/popup/popup-layer.vue";
import IconChevron from "@/components/client/icons/icon-chevron.vue";
import { BOARD_YOUTUBE_CATEGORIES, BOARD_REPORT_CATEGORIES, BOARD_FORMS_CATEGORIES } from "@/assets/variables";

const categoriesYoutube = Object.values(BOARD_YOUTUBE_CATEGORIES);
const categoriesReport = Object.values(BOARD_REPORT_CATEGORIES);
const categoriesForms = Object.values(BOARD_FORMS_CATEGORIES);

export default {
    components: {
        ClientPage,
        MainBanners,
        MainSection,
        MainVisual,
        MainTextBanner,
        MainAuctionsBanner,
        // BoardList,
        PopupLayer,
        IconChevron,
    },
    props: {
        isMain: { type: Boolean, default: true },
    },
    data: () => ({
        categoriesYoutube,
        categoriesReport,
        categoriesForms,
        solution: [],
        server: [],
        network: [],
        software: [],
    }),
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            let solution = await api.v1.banners.gets({ params: { code: "partners", name: "보안솔루션" } });
            let server = await api.v1.banners.gets({ params: { code: "partners", name: "서버 및 주변기기" } });
            let network = await api.v1.banners.gets({ params: { code: "partners", name: "네트워크" } });
            let software = await api.v1.banners.gets({ params: { code: "partners", name: "상용소프트웨어" } });
            this.solution = solution.banner?.slides;
            this.server = server.banner?.slides;
            this.network = network.banner?.slides;
            this.software = software.banner?.slides;
            // console.log(server);
        },
    },
};
</script>

<style lang="scss" scoped>
.new-main {
    padding: 12px 0;
    display: flex;
    align-items: center;
    min-height: calc(100vh - var(--header-body));
}
.about-banner {
    width: 100%;
    position: relative;
    &__logo {
        position: absolute;
        right: 0;
        top: 0;
        transform: translateY(-50%);
        z-index: 2;
        max-width: 160px;
        .v-image {
            box-shadow: var(--shadow);
        }
    }
    &__inner {
        position: relative;
        width: 100vw;
        height: 160px;
        max-width: 100vw;
        left: 50%;
        transform: translateX(-50%);
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .about-banner {
        &__logo {
            max-width: 260px;
        }
        &__inner {
            height: 200px;
        }
    }
}
@media (min-width: 1024px) {
    .about-banner {
        &__logo {
            max-width: 382px;
        }
        &__inner {
            height: 350px;
        }
    }
}
@media (min-width: 1200px) {
}
</style>