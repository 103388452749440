var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "app--main"
  }, [_c('section', {
    staticClass: "new-main"
  }, [_c('v-container', [_c('h3', {
    staticClass: "tit mb-90 mb-md-80 mb-lg-120 word-keep-all"
  }, [_vm._v(" We sincerely thank everyone "), _c('br'), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v("who visited Link-Net Korea.")])]), _c('div', {
    staticClass: "about-banner"
  }, [_c('div', {
    staticClass: "about-banner__logo"
  }, [_c('v-img', {
    staticClass: "w-100",
    attrs: {
      "src": "/images/sub/about/about/logo.jpg",
      "data-aos": "fade-left",
      "data-aos-delay": "200"
    }
  })], 1), _c('v-img', {
    staticClass: "about-banner__inner",
    attrs: {
      "src": "/images/sub/about/about/banner.jpg"
    }
  })], 1), _c('div', {
    staticClass: "mt-40 mt-lg-60"
  }, [_c('p', {
    staticClass: "tit-txt line-height-15 mb-6 mb-lg-12"
  }, [_vm._v(" Link-Net Korea is a young company created by top engineers and consultants to provide a safer and more prosperous Internet network environment. ")]), _c('p', {
    staticClass: "tit-txt line-height-15 text-lg-pre-wrap"
  }, [_vm._v(" We carry out a number of projects for major information and communication infrastructure such as government agencies, public offices, and national defense, as well as financial institutions and private companies such as banks, securities firms, and capital. ")])])])], 1), _c('popup-layer')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }